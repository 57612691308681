import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Backdrop, CircularProgress, Box, Fab } from "@mui/material";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { useDispatch } from "react-redux";
import { setAuthUser } from "../../reducers/user";
import { updateConfirmOrderPaymentRequest } from "../../reducers/appState";

function KbzPayRedirect() {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(()=> {
    const serverRequest = async()=> {

      let userId = null;
      let payment_gateway_redirect_url = null;
      let location_string = window.location['href'].split('?');
      if(location_string.length > 1){
        let all_params = location_string[1].split("&");
        let userIdString = all_params[0].split("=") ;
        userId = userIdString[1];
        console.log(userIdString[1],'userId')
        await forceLogin(userId);


        let payment_gateway_redirect_url_string = all_params[1].split("=") ;
        payment_gateway_redirect_url = `${payment_gateway_redirect_url_string[1]}?${location_string[2]}`;
        console.log(payment_gateway_redirect_url,'payment_gateway_redirect_url')

      }
      console.log(payment_gateway_redirect_url,"KbzPayRedirect Call Back")
      if(payment_gateway_redirect_url) {
        setTimeout(() => {
          console.log(payment_gateway_redirect_url,'running ******* payment_gateway_redirect_url')
          window.location.href = payment_gateway_redirect_url;
        },7000)
      }
    }
    serverRequest();
  },[])

  const forceLogin = async (userId) => {
    try {
      let obj = {
        method: "post",
        url: ApiCollections.foreUserLogin,
        params: {
          user_id: userId,
        },
      };
      let response = await ApiRequest(obj);
      if (response.data.code === 200) {
        dispatch(setAuthUser(response.data.data)); //set aut user data to state
        if(response.data.confirm_payment) {
          dispatch(updateConfirmOrderPaymentRequest(response.data.confirm_payment));
        }
        localStorage.setItem("userTokenID", response.data.data.user.id);
        localStorage.setItem("userLoginStatus", "phone");
        localStorage.setItem("selectedPayment", "KBZPay");
        localStorage.setItem("paymentRedirectFrom", "Mobile");
      } else {
        console.log("Something Wrong Login with Phone!");
      }
    } catch (err) {
      console.log(`Fetch failed: ${err.message}`);
    }
  };


  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <Box sx={{ m: 1, position: "relative" }}>
          <Fab aria-label="save" sx={{ backgroundColor: "#777" }}>
            <img
              src={require("../../assets/images/Logopng.png")}
              style={{ width: 90, height: 90, position: "relative" }}
            />
          </Fab>
          <CircularProgress
            size={130}
            sx={{
              color: "gold",
              position: "absolute",
              top: -41,
              left: -41,
              zIndex: 1,
            }}
          />
        </Box>
      </Backdrop>
    </div>
  );
}

export default KbzPayRedirect;
