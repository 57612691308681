import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, Box, Fab } from "@mui/material";

import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import { setAuthUser } from "../../reducers/user";
import getCustomerCartItems from "../../mobile/helper/CustomerCart";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateConfirmOrderPaymentRequest } from "../../reducers/appState";

function PWACallBack() {
  const location = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const {confirmOrderPaymentRequest} = useSelector((state) => state.appState);

  const [paymentQueryTime,setPaymentQueryTime] = useState(new Date());

  const queryWaitCallBack = (fn, date) => {
    return setTimeout(fn, date - Date.now());
  }
  useEffect(()=> {
    console.log("PWA Call Back")
    let paymentRedirectFrom = localStorage.getItem("paymentRedirectFrom");
    if(paymentRedirectFrom) {
      if(paymentRedirectFrom == "Mobile"){
        localStorage.removeItem("paymentRedirectFrom");
        window.location.href = "mobile/back-to-mobile";
      }else{
        localStorage.removeItem("paymentRedirectFrom");
        window.location.href = "/";
      }
    }else{
      const params = new URLSearchParams(location.search);
      let merch_order_id = params.get('merch_order_id');
      let prepay_id = params.get('prepay_id');
      getUserInfo();
      if(merch_order_id) {
        console.log(merch_order_id,'=> merch_order_id',prepay_id,'=> prepay_idprepay_id')
        queryWaitCallBack(()=> {
          paymentQuery();
        },paymentQueryTime)
      }
    }
  },[paymentQueryTime])

  const paymentQuery = async() => {
    const params = new URLSearchParams(location.search);
    let merch_order_id = params.get('merch_order_id');
    let prepay_id = params.get('prepay_id');
    let selectedPayment = localStorage.getItem("selectedPayment");
    if(selectedPayment) {
      let payRequestObj = {
        method: "post",
        url: ApiCollections.queryKbzPayPWA,
        params: {
          customer_id: localStorage.getItem("userTokenID"),
          payment_method: selectedPayment,
          order_id: merch_order_id
        },
      };
      let response = await ApiRequest(payRequestObj);
      if (response.data.code === 200) {
        if(response.data.payment_status === 'PAY_SUCCESS') {
          localStorage.removeItem("selectedPayment");
          // gotoOrderList(response.data.transaction_id); // pass transaction id to confirm order api

          if(confirmOrderPaymentRequest) {
            let confirmOderObj = confirmOrderPaymentRequest;
            console.log(confirmOderObj);
            confirmOderObj.params.transaction_id = merch_order_id;
            console.log(confirmOderObj);
            let response = await ApiRequest(confirmOderObj);
            if (response.data.code === 200) {
              toast.success("Order Success", {
                className: "toastColor",
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
            } else {
              toast.error("Order Fail", {
                className: "toastColor",
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
            }
          }
          dispatch(updateConfirmOrderPaymentRequest(null));
          setOpen(false);
          navigate("/OrderList");
        }
        if(response.data.payment_status === 'WAIT_PAY') {
          console.log("WAIT_PAY");
          let queryTimeSeconds = new Date();
          queryTimeSeconds.setSeconds(queryTimeSeconds.getSeconds() + 30);
          setPaymentQueryTime(queryTimeSeconds);
        }
        if(response.data.payment_status === 'PENDING_PAY') {
          console.log(merch_order_id + " => PENDING_PAY");
          let queryTimeSeconds = new Date();
          queryTimeSeconds.setSeconds(queryTimeSeconds.getSeconds() + 30);
          setPaymentQueryTime(queryTimeSeconds);
        }
      } else {
        console.log("Payment Query Fail");
        toast.error("Payment Query Fail", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
        
      }
    }else{
      console.log("Payment Query Fail cause not have selectedPayment");
        toast.error("Payment Query Fail", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          navigate("/");
        },5000)
    }
  }
  const getUserInfo = async () => {
    let obj = {
      method: "get",
      url: ApiCollections.userInfo,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    response = response.data;
    if (response.code === 200) {
      localStorage.setItem("userTokenPhone", response.data.phone_number);
      localStorage.setItem("userTokenEmail", response.data.email);
    }
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <Box sx={{ m: 1, position: "relative" }}>
          <Fab aria-label="save" sx={{ backgroundColor: "#777" }}>
            <img
              src={require("../../assets/images/Logopng.png")}
              style={{ width: 90, height: 90, position: "relative" }}
            />
          </Fab>
          <CircularProgress
            size={130}
            sx={{
              color: "gold",
              position: "absolute",
              top: -41,
              left: -41,
              zIndex: 1,
            }}
          />
        </Box>
      </Backdrop>
    </div>
  );
}

export default PWACallBack;
