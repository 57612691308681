import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import userReducer from "./reducers/user";
import bottomNavReducer from "./reducers/bottomNav";
import cartReducer from "./reducers/cartSlice";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import checkoutReducer from "./reducers/checkoutSlice";
import orderNumber from "./reducers/orderNumber";
import reviewSlice from "./reducers/reviewSlice";
import countRTSSSlice from "./reducers/countRTSSSlice";
import couponSlice from "./reducers/couponSlice";
import appStateSlice from "./reducers/appState";
const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  nav: bottomNavReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  orderNumber: orderNumber,
  review: reviewSlice,
  RTSS: countRTSSSlice,
  coupon: couponSlice,
  appState: appStateSlice,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
