import React from "react";
import "../../mobile/product/PaymentCss.scss";

import Lottie from "lottie-react";
import onlinepay from "../../assets/onlinepay.json";

export default function BackToMobile() {
    const closeWindow = () => {
        window.open("about:blank", "_self").close();
    }
    return (
        <div className="paymentDiv">
        <div className="header" id="topHeader">
          <div className="title">Payment Success</div>
        </div>
        <div className="iconpay">
          <Lottie animationData={onlinepay} loop={true} id="lotpay" />
        </div>
        <div className="saveBtn" onClick={closeWindow}>
            Go Back To Mobile
        </div>
      </div>
    )
}

