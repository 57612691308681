import React, { useState, useEffect } from "react";
import "../../mobile/product/PaymentCss.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowCircleLeft, RadioButtonChecked } from "@mui/icons-material";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Lottie from "lottie-react";
import onlinepay from "../../assets/onlinepay.json";
import { useSelector, useDispatch } from "react-redux";
import {
  setCheckoutItems,
  updateCart,
  addToCart,
} from "../../reducers/cartSlice";
import { useTranslation } from "react-i18next";

const MultipleProductPayment = () => {
  const { t } = useTranslation();

  const {
    checkoutItems,
    totalCheckoutAmount,
    totalCheckoutDiscountAmount,
    cart,
  } = useSelector((state) => state.cart);

  const stateCoupon = useSelector((state) => state.coupon);

  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    getpayMed();
    getAddressList();
  }, []);

  const navigate = useNavigate();
  const goback = () => {
    if (!checkoutItems.length) {
      navigate("/");
    }
    navigate(-1);
  };

  const [loading, setLoading] = useState(false);

  const [addressList, setAddressList] = useState([]);
  const getAddressList = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.addressList,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setAddressList(response.data);
      getDeliveryFee(response.data.township_id, response.data.region_id);
    } else {
      setAddressList(response.data);
    }
  };

  const [deliveryFee, setDeliveryFee] = useState([]);
  const [Cod, setCod] = useState([]);
  const [deliveryExtra, setDeliveryExtra] = useState([]);
  const getDeliveryFee = async (townID, regionID) => {
    let productIdsArr = [];
    checkoutItems.map((product) => {
      productIdsArr.push({
        id: product.product_id,
        quantity: product.quantity,
        type: product.type,
      });
    });
    let requestObj = {
      method: "post",
      url: ApiCollections.deliveryFeeApi,
      params: {
        township_id: townID,
        region_id: regionID,
        productIds_arr: productIdsArr,
      },
    };

    let response = await ApiRequest(requestObj);

    if (response.data.code === 200) {
      setDeliveryFee(response.data.data.delivery_fee);
      setCod(response.data.data.cod_status);

      const { finalTotal } = response.data.data.extra_charge.reduce(
        (total, item) => {
          const { shipping_type_price } = item;
          const data = shipping_type_price;
          total.finalTotal += data;
          return total;
        },
        { finalTotal: 0 }
      );
      setDeliveryExtra(finalTotal);
    }
  };

  const [payMed, setpayMed] = useState([]);
  const getpayMed = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.paymentMethodApi,
    };
    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setpayMed(response.data);
    }
  };

  const [choose, setCashOnDeli] = useState("0");
  const goCashDeli = (index) => {
    setCashOnDeli(index);
  };

  const noava = () => {
    toast.error("UnAvaliable cash on delivery in this township", {
      className: "toastColor",
      draggable: true,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const gotoOrderList = async () => {
    if (choose == 0) {
      toast.error("Please choose payment method", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (addressList == null) {
        toast.error("Please Add Address", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        /*** Order Product Start**/
        setLoading(true);
        let cartProducts = cart;
        let orderProducts = [];
        let checkoutItemsCartIds = [];
        let orderTotal = totalCheckoutAmount; // get total amount of checkut items
        checkoutItems.map((product) => {
          checkoutItemsCartIds.push(product.id);
          let productId =
            product.type == "product"
              ? product.product_id
              : product.type == "item"
              ? product.product_id
              : null;
          cartProducts = cartProducts.filter((item) => item.id !== productId);
          orderProducts.push({
            product_id: product.type == "product" ? product.product_id : null,
            item_id: product.type == "item" ? product.product_id : null,
            quantity: product.quantity,
            price: product.price,
            promotion_price: product.promotion_price,
            seller_id: product.seller_id,
            product_type: product.type,
          });
        });

        let requestObj = {
          method: "post",
          url: ApiCollections.confirmPaymenApi,
          params: {
            payment_status: choose,
            user_id: localStorage.getItem("userTokenID"),
            region_id: addressList.region_id,
            township_id: addressList.township_id,
            address: addressList.address,
            coupon_id: stateCoupon.couponID,
            coupon_used_status: stateCoupon.couponStatus,
            coupon_price: stateCoupon.couponPrice,
            total: Number.parseFloat(
              orderTotal -
                totalCheckoutDiscountAmount +
                deliveryFee +
                deliveryExtra -
                stateCoupon.couponPrice
            ).toFixed(2),
            totalDiscount: totalCheckoutDiscountAmount,
            order_products: orderProducts,
          },
        };

        let response = await ApiRequest(requestObj);
        if (response.data.code === 200) {
          dispatch(setCheckoutItems([]));
          dispatch(updateCart(cartProducts));

          let deleCartRequestObj = {
            method: "delete",
            url: ApiCollections.deleteCartItems,
            params: {
              cart_ids: checkoutItemsCartIds,
              customer_id: localStorage.getItem("userTokenID"),
            },
          };
          let cartDeleteResponse = await ApiRequest(deleCartRequestObj);

          if (cartDeleteResponse.data.code !== 200) {
            dispatch(addToCart(cartDeleteResponse.data.data));
          } else {
            toast.error("Cart Delete Faile", {
              className: "toastColor",
              draggable: true,
              position: toast.POSITION.TOP_CENTER,
            });
          }
          setLoading(false);
          toast.success("Order Success", {
            className: "toastColor",
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
          navigate("/OrderList");
        } else {
          setLoading(false);
          toast.error("Order Fail", {
            className: "toastColor",
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
        /*** Order Product End**/
      }
    }
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      {loading && <Loading open={loading} />}
      <div className="paymentDiv">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("paymentMethod")}</div>
        </div>
        <div className="iconpay">
          <Lottie animationData={onlinepay} loop={true} id="lotpay" />
        </div>
        {payMed.length > 0 && (
          <div>
            {Cod == 1 ? (
              <div className="cashAll">
                <p className="title">{t("selectMethod")}</p>
                <div
                  className="cash"
                  onClick={() => goCashDeli(payMed[0].payment_type)}
                >
                  <RadioButtonChecked
                    id={`${
                      choose == payMed[0].payment_type
                        ? "activeselectIcon"
                        : "selectIcon"
                    }`}
                  />
                  <span className="cashtitle">{payMed[0].payment_method}</span>
                </div>
              </div>
            ) : (
              <div className="cashAll">
                <p className="title">{t("selectMethod")}</p>
                <div className="cash" onClick={() => noava()}>
                  <RadioButtonChecked id="selectIcon" />
                  <span className="cashtitle">{payMed[0].payment_method}</span>
                </div>
              </div>
            )}
            <div className="payCard">
              <div
                className={`${
                  choose == payMed[1].payment_type ? "active" : "threepay"
                }`}
                onClick={() => goCashDeli(payMed[1].payment_type)}
              >
                <div className="photo">
                  <img
                    className="waveimg"
                    src={require("../../assets/images/wave.jpg")}
                  />
                </div>
                <div className="title">{payMed[1].payment_method}</div>
              </div>
              <div
                className={`${
                  choose == payMed[2].payment_type ? "active" : "threepay"
                }`}
                onClick={() => goCashDeli(payMed[2].payment_type)}
              >
                <div className="photo">
                  <img
                    className="waveimg"
                    src={require("../../assets/images/kbz.png")}
                  />
                </div>
                <div className="title">{payMed[2].payment_method}</div>
              </div>
              <div
                className={`${
                  choose == payMed[3].payment_type ? "active" : "threepay"
                }`}
                onClick={() => goCashDeli(payMed[3].payment_type)}
              >
                <div className="photo">
                  <img
                    className="waveimg"
                    src={require("../../assets/images/cb.jpg")}
                  />
                </div>
                <div className="title">{payMed[3].payment_method}</div>
              </div>
            </div>
          </div>
        )}
        <div className="saveBtn" onClick={gotoOrderList}>
          {t("comPayment")}
        </div>
      </div>
    </>
  );
};

export default MultipleProductPayment;
