import React, { useState, useEffect } from "react";
import "../../pc/product/PaymentCss.scss";
import Wrapper from "../Wrapper";
import { Container } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowCircleLeft, RadioButtonChecked } from "@mui/icons-material";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Lottie from "lottie-react";
import onlinepay from "../../assets/onlinepay.json";
import { useDispatch, useSelector } from "react-redux";
import { getAllData } from "../../mobile/httpConfig";
import { useTranslation } from "react-i18next";
import { useLastLocation } from "react-router-dom-last-location";
import QRCode from "react-qr-code";
import { updateConfirmOrderPaymentRequest } from "../../reducers/appState";
import Timer from "./Timer";

const Payment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [displayQr, setDisplayQR] = useState(false);
  const [kbzQR, setKbzQR] = useState(null);
  const [paymentOrderId, setPaymentOrderId] = useState(null);
  const [paymentQueryTime, setPaymentQueryTime] = useState(new Date());

  const [selectedPayment, setSelectedPayment] = useState(null);

  const state = useSelector((state) => state.checkout);
  const stateCoupon = useSelector((state) => state.coupon);

  useEffect(() => {
    window.scrollTo(0, 0);
    getpayMed();
    getCheckOut();
    getDeliveryFee();
    getAddressList();
  }, []);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [checkShow, setCheckOut] = useState([]);
  const [totalQty, setTotalQty] = useState([]);
  const [totalCal, setTotalCal] = useState([]);
  const [promotionCal, setPromotionCal] = useState([]);
  const getCheckOut = async () => {
    const response = await (
      await getAllData(
        `check_out?product_id=${state.product_id}&item_id=${state.item_id}&qty=${state.qty}`
      )
    ).json();
    if (response.code === 200) {
      setCheckOut(response.data);
      setTotalQty(response.quantity);
      if (response.data.product_promotion_price !== 0) {
        if (response.data.price) {
          setTotalCal(response.data.price * response.quantity);
          setPromotionCal(
            (response.data.price - response.data.product_promotion_price) *
              response.quantity
          );
        } else {
          setTotalCal(response.data.product_price * response.quantity);
          setPromotionCal(
            (response.data.product_price -
              response.data.product_promotion_price) *
              response.quantity
          );
        }
      } else {
        if (response.data.price) {
          setTotalCal(response.data.price * response.quantity);
          setPromotionCal(0);
        } else {
          setTotalCal(response.data.product_price * response.quantity);
          setPromotionCal(0);
        }
      }
    }
  };

  const [addressList, setAddressList] = useState([]);

  const getAddressList = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.addressList,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setAddressList(response.data);
      getDeliveryFee(response.data.township_id, response.data.region_id);
    } else {
      setAddressList(response.data);
    }
  };

  const [deliveryFee, setDeliveryFee] = useState([]);
  const [Cod, setCod] = useState([]);
  const [deliveryExtra, setDeliveryExtra] = useState([]);
  const getDeliveryFee = async (townID, regionID) => {
    let productIdsArr = [];
    if (state.product_id != null) {
      productIdsArr.push({
        id: state.product_id,
        quantity: state.qty,
        type: "product",
      });
    } else {
      productIdsArr.push({
        id: state.item_id,
        quantity: state.qty,
        type: "item",
      });
    }

    let requestObj = {
      method: "post",
      url: ApiCollections.deliveryFeeApi,
      params: {
        township_id: townID,
        region_id: regionID,
        productIds_arr: productIdsArr,
      },
    };

    let response = await ApiRequest(requestObj);

    if (response.data.code === 200) {
      setDeliveryFee(response.data.data.delivery_fee);
      setCod(response.data.data.cod_status);

      const { finalTotal } = response.data.data.extra_charge.reduce(
        (total, item) => {
          const { shipping_type_price } = item;
          const data = shipping_type_price;
          total.finalTotal += data;
          return total;
        },
        { finalTotal: 0 }
      );
      setDeliveryExtra(finalTotal);
    }
  };

  const [payMed, setpayMed] = useState([]);
  const getpayMed = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.paymentMethodApi,
    };
    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setpayMed(response.data);
    }
  };

  const [choose, setCashOnDeli] = useState("0");
  const goCashDeli = (index) => {
    setCashOnDeli(index);
  };

  const queryWaitCallBack = (fn, date) => {
    return setTimeout(fn, date - Date.now());
  };
  useEffect(() => {
    if (paymentOrderId) {
      queryWaitCallBack(() => {
        paymentQuery();
      }, paymentQueryTime);
    }
  }, [paymentOrderId, paymentQueryTime]);
  const paymentQuery = async () => {
    if (selectedPayment) {
      let payRequestObj = {
        method: "post",
        url: ApiCollections.queryKbzPay,
        params: {
          customer_id: localStorage.getItem("userTokenID"),
          payment_method: selectedPayment,
          order_id: paymentOrderId,
        },
      };
      let response = await ApiRequest(payRequestObj);
      if (response.data.code === 200) {
        if (response.data.payment_status === "PAY_SUCCESS") {
          setSelectedPayment(null);
          gotoOrderList(response.data.transaction_id); // pass transaction id to confirm order api
        }
        if (response.data.payment_status === "WAIT_PAY") {
          // console.log("WAIT_PAY");
          let queryTimeSeconds = new Date();
          queryTimeSeconds.setSeconds(queryTimeSeconds.getSeconds() + 30);
          setPaymentQueryTime(queryTimeSeconds);
        }
        if (response.data.payment_status === "PENDING_PAY") {
          // console.log(paymentOrderId + " => PENDING_PAY");
          let queryTimeSeconds = new Date();
          queryTimeSeconds.setSeconds(queryTimeSeconds.getSeconds() + 30);
          setPaymentQueryTime(queryTimeSeconds);
        }
      } else {
        // console.log("Payment Query Fail");
        toast.error("Payment Query Fail", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
        paymentWaittingTimeOut();
      }
    } else {
      // console.log("Payment Query Fail");
      toast.error("Payment Query Fail", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const requestPay = async () => {
    if (choose == 0) {
      toast.error("Please choose payment method", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (selectedPayment) {
        if (selectedPayment == "COD") {
          gotoOrderList();
        } else {
          if (selectedPayment == "KBZPay") {
            let requestObj = {
              method: "post",
              url: ApiCollections.confirmPaymenApi,
              params: {
                user_id: localStorage.getItem("userTokenID"),
                payment_status: choose,
                region_id: addressList.region_id,
                township_id: addressList.township_id,
                address: addressList.address,
                coupon_id: stateCoupon.couponID,
                coupon_used_status: stateCoupon.couponStatus,
                coupon_price: stateCoupon.couponPrice,
                total: Number.parseFloat(
                  totalCal +
                    deliveryFee +
                    deliveryExtra -
                    promotionCal -
                    stateCoupon.couponPrice
                ).toFixed(2),
                totalDiscount: Number.parseFloat(promotionCal).toFixed(2),
                order_products: [
                  {
                    product_id: state.product_id,
                    item_id: state.item_id,
                    quantity: state.qty,
                    seller_id: checkShow.seller_id,
                    price: checkShow.product_price || checkShow.price,
                    promotion_price: checkShow.product_promotion_price,
                  },
                ],
              },
            };
            dispatch(updateConfirmOrderPaymentRequest(requestObj));
            let payRequestObj = {
              method: "post",
              url: ApiCollections.initKbzPay,
              params: {
                customer_id: localStorage.getItem("userTokenID"),
                payment_method: selectedPayment,
                total_amount: Number.parseFloat(
                  totalCal +
                    deliveryFee +
                    deliveryExtra -
                    promotionCal -
                    stateCoupon.couponPrice
                ).toFixed(2),
                title: "Pay for MGD Mall",
              },
            };

            let response = await ApiRequest(payRequestObj);
            if (response.data.code === 200) {
              toast.success("Request to Pay Success", {
                className: "toastColor",
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
              setKbzQR(response.data.data.qrCode);

              let queryTimeSeconds = new Date();
              queryTimeSeconds.setSeconds(queryTimeSeconds.getSeconds() + 30);
              setPaymentQueryTime(queryTimeSeconds);

              setPaymentOrderId(response.data.data.merch_order_id);
              setDisplayQR(true);
            } else {
              toast.error("Request to Pay Fail", {
                className: "toastColor",
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
            }
          } else {
            toast.error(
              "Please choose other payment method! Currently we don't accept this payment yet!",
              {
                className: "toastColor",
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              }
            );
          }
        }
      } else {
        toast.error("Please choose payment method", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };
  const gotoOrderList = async (paymentTransactionId = null) => {
    if (choose == 0) {
      toast.error("Please choose payment method", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      let transId = null;
      if (selectedPayment !== "COD") {
        transId = paymentTransactionId ? paymentTransactionId : null;
      }
      let requestObj = {
        method: "post",
        url: ApiCollections.confirmPaymenApi,
        params: {
          transaction_id: transId,
          user_id: localStorage.getItem("userTokenID"),
          payment_status: choose,
          region_id: addressList.region_id,
          township_id: addressList.township_id,
          address: addressList.address,
          coupon_id: stateCoupon.couponID,
          coupon_used_status: stateCoupon.couponStatus,
          coupon_price: stateCoupon.couponPrice,
          total: Number.parseFloat(
            totalCal +
              deliveryFee +
              deliveryExtra -
              promotionCal -
              stateCoupon.couponPrice
          ).toFixed(2),
          totalDiscount: Number.parseFloat(promotionCal).toFixed(2),
          order_products: [
            {
              product_id: state.product_id,
              item_id: state.item_id,
              quantity: state.qty,
              seller_id: checkShow.seller_id,
              price: checkShow.product_price || checkShow.price,
              promotion_price: checkShow.product_promotion_price,
            },
          ],
        },
      };

      let response = await ApiRequest(requestObj);
      if (response.data.code === 200) {
        toast.success("Order Success", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
        navigate("/OrderList");
      } else {
        toast.error("Order Fail", {
          className: "toastColor",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };
  const paymentWaittingTimeOut = () => {
    toast.error("Payment Watting Time is Over", {
      className: "toastColor",
      draggable: true,
      position: toast.POSITION.TOP_CENTER,
    });
    setDisplayQR(false);
    setPaymentOrderId(null);
    setSelectedPayment(null);
  };

  const noava = () => {
    toast.error("UnAvaliable cash on delivery in this township", {
      className: "toastColor",
      draggable: true,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  // const { lastLocation } = useLastLocation();
  // console.log(lastLocation);

  return (
    <>
      <ToastContainer autoClose={3000} />

      <Wrapper>
        <ToastContainer autoClose={3000} />
        {loading && <Loading open={loading} />}
        <Container>
          <div className="paymentPc">
            <div className="iconpay">
              <Lottie animationData={onlinepay} loop={true} id="lotpay" />
            </div>
            {!displayQr && (
              <div className="payDay">
                {payMed.length > 0 && (
                  <div>
                    {Cod == 1 ? (
                      <div className="cashAll">
                        <p className="title">{t("selectMethod")}</p>
                        <div
                          className="cash"
                          onClick={() => {
                            // console.log(payMed[0]);
                            setSelectedPayment(payMed[0].payment_type);
                            goCashDeli(payMed[0].payment_type);
                          }}
                        >
                          <RadioButtonChecked
                            id={`${
                              choose == payMed[0].payment_type
                                ? "activeselectIcon"
                                : "selectIcon"
                            }`}
                          />
                          <span className="cashtitle">
                            {payMed[0].payment_method}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="cashAll">
                        <p className="title">{t("selectMethod")}</p>
                        <div className="cash" onClick={() => noava()}>
                          <RadioButtonChecked id="selectIcon" />
                          <span className="cashtitle">
                            {payMed[0].payment_method}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="payCard">
                      <div
                        className={`${
                          choose == payMed[1].payment_type
                            ? "active"
                            : "threepay"
                        }`}
                        onClick={() => {
                          // console.log(payMed[1]);
                          setSelectedPayment(payMed[1].payment_type);
                          goCashDeli(payMed[1].payment_type);
                        }}
                      >
                        <div className="photo">
                          <img
                            className="waveimg"
                            src={require("../../assets/images/wave.jpg")}
                          />
                        </div>
                        <div className="title">{payMed[1].payment_method}</div>
                      </div>
                      <div
                        className={`${
                          choose == payMed[2].payment_type
                            ? "active"
                            : "threepay"
                        }`}
                        onClick={() => {
                          // console.log(payMed[2]);
                          setSelectedPayment(payMed[2].payment_type);
                          goCashDeli(payMed[2].payment_type);
                        }}
                      >
                        <div className="photo">
                          <img
                            className="waveimg"
                            src={require("../../assets/images/kbz.png")}
                          />
                        </div>
                        <div className="title">{payMed[2].payment_method}</div>
                      </div>
                      <div
                        className={`${
                          choose == payMed[3].payment_type
                            ? "active"
                            : "threepay"
                        }`}
                        onClick={() => {
                          // console.log(payMed[3]);
                          setSelectedPayment(payMed[3].payment_type);
                          goCashDeli(payMed[3].payment_type);
                        }}
                      >
                        <div className="photo">
                          <img
                            className="waveimg"
                            src={require("../../assets/images/cb.jpg")}
                          />
                        </div>
                        <div className="title">{payMed[3].payment_method}</div>
                      </div>
                    </div>
                    <div className="saveBtn" onClick={requestPay}>
                      {t("comPayment")}
                    </div>
                  </div>
                )}
              </div>
            )}
            {displayQr && (
              <div className="payDay" style={{ marginTop: 20, marginLeft: 20 }}>
                <QRCode
                  size={100}
                  style={{ height: "auto", maxWidth: 250, width: "100%" }}
                  value={kbzQR}
                  viewBox={`0 0 256 256`}
                />
                <Timer
                  initialMinute={5}
                  initialSeconds={0}
                  timeComplete={paymentWaittingTimeOut}
                />
              </div>
            )}
          </div>
        </Container>
      </Wrapper>
    </>
  );
};

export default Payment;
