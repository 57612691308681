import { createSlice } from "@reduxjs/toolkit";

const appState = createSlice({
  name: "app",
  initialState: {
    appLoading: false,
    confirmOrderPaymentRequest: null
  },
  reducers: {
    updateConfirmOrderPaymentRequest: (state, action) => {
      state.confirmOrderPaymentRequest = action.payload;
    },
  }
});

export const {
  updateConfirmOrderPaymentRequest
} = appState.actions;
export default appState.reducer;
