import React, { useState, useEffect } from "react";
import "../../mobile/account/RecieveCss.scss";
import { useNavigate } from "react-router-dom";
import { ArrowCircleLeft } from "@mui/icons-material";
import Lottie from "lottie-react";
import noorder from "../../assets/noorder.json";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Recieve = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goback = () => {
    navigate("/PersonalCenter");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getReceive();
  }, []);

  const [loading, setLoading] = useState(false);

  const gotoproduct = (product_id, item_status, item_id, deleteStatus) => {
    if (deleteStatus == 1) {
      toast.error("This product has been deleted.", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (item_status != 0) {
        navigate(`/products/product-detail/${product_id}/${item_id}`);
      } else {
        navigate(`/products/product-detail/${product_id}`);
      }
    }
  };

  const [showReceive, setReceive] = useState([]);
  const getReceive = async () => {
    setLoading(true);
    let obj = {
      method: "get",
      url: ApiCollections.receiveListApi,
      params: {
        user_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(obj);
    setLoading(false);
    response = response.data;
    if (response.code === 200) {
      setReceive(response.data);
    }
  };

  const godeatil = (orderID) => {
    navigate(`/NoCancelDetail/${orderID}`);
  };

  const goReview = (orderid, productid) => {
    navigate(`/ReviewPage/${orderid}/${productid}`);
  };

  return (
    <>
      {loading && <Loading open={loading} />}
      <ToastContainer draggable={false} autoClose={3000} />
      <div className="recieveDiv">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("Receive")}</div>
        </div>
        {showReceive.length > 0 ? (
          <div>
            {showReceive.map((orderItem, orderIndex) => (
              <div className="listAll" key={orderIndex}>
                <div className="top">
                  <div className="orderNo">
                    {t("OrderNumber")} : {orderItem.order_code}
                  </div>
                  <div className="date">{orderItem.date}</div>
                </div>
                {orderItem.receiveLists.map((listItem, listIndex) => (
                  <div key={listIndex} className="oneProduct">
                    <div className="orderlistDiv">
                      <div className="cardImage">
                        <img src={listItem.photo} />
                      </div>
                      <div>
                        <div className="title">
                          {listItem.product_name || listItem.item_name}
                        </div>
                        <div className="price">
                          {listItem.promotion_price !== 0 ? (
                            <span className="ori">
                              {listItem.promotion_price} ks &nbsp;&nbsp;
                              <s>{listItem.price}ks</s>
                            </span>
                          ) : (
                            <span className="ori">{listItem.price} ks</span>
                          )}
                        </div>
                        <div className="qty">
                          {t("Quantity")}:{" "}
                          <span className="count">{listItem.quantity}</span>
                        </div>

                        <div className="goBtn">
                          <div
                            className="goProduct"
                            onClick={() =>
                              gotoproduct(
                                listItem.product_id,
                                listItem.item_status,
                                listItem.item_id,
                                listItem.deleted_product_status
                              )
                            }
                          >
                            {t("ProductDetail")}
                          </div>
                          {listItem.cancel_status == 0 && (
                            <div
                              className="goReview"
                              onClick={() =>
                                goReview(
                                  orderItem.order_id,
                                  listItem.product_id
                                )
                              }
                            >
                              {t("Review")}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {listItem.cancel_status == 1 && (
                      <div className="shopCancel">
                        We're sorry, this product has been cancelled by an
                        unforeseeable circumstances.
                      </div>
                    )}
                  </div>
                ))}
                <div className="bottom">
                  <div className="paid">
                    {t("Payment")}&nbsp;:&nbsp;{orderItem.payment_method}
                  </div>
                  <div
                    className="detail"
                    onClick={() => godeatil(orderItem.order_id)}
                  >
                    {t("OrderDetail")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="noOrder">
            <div className="text">{t("NoProduct")}</div>
            <div className="iconWrap">
              <Lottie animationData={noorder} loop={true} id="lot" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Recieve;
