import React, { useEffect, useState } from "react";
import "./OrderListCss.scss";
import Wrapper from "../Wrapper";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Tab } from "@mui/material";
import OrderListNav from "./OrderListNav";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Loading from "../../components/commons/Loading";
import Lottie from "lottie-react";
import nodata from "../../assets/nodata.json";
import { Star } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReviewPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
    getreviewProduct();
  }, []);

  const gotoproduct = (product_id, item_status, item_id, deleteStatus) => {
    if (deleteStatus == 1) {
      toast.error("This product has been deleted.", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (item_status != 0) {
        navigate(`/products/product-detail/${product_id}/${item_id}`);
      } else {
        navigate(`/products/product-detail/${product_id}`);
      }
    }
  };

  const [loading, setLoading] = useState(false);

  const [reviewProduct, setreviewProduct] = useState([]);
  const getreviewProduct = async () => {
    setLoading(true);
    let requestObj = {
      method: "post",
      url: ApiCollections.reviewHistoryApi,
      params: {
        customer_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.data.code === 200) {
      setreviewProduct(response.data.data);
    }
  };

  return (
    <Wrapper>
      <Container>
        <div className="OrderDiv">
          <OrderListNav />
          <div className="order">
            <div className="rightTitle">
              <span className="!w-auto text-[20px] pb-1 font-bold !border-b-[3px] !border-secondColor">
                {t("Review")}
              </span>
            </div>
            <Box sx={{ width: "100%" }}>
              {loading && (
                <div className="w-full h-full flex justify-center items-center bg-white">
                  <Loading open={loading} />
                </div>
              )}
              {reviewProduct.length > 0 ? (
                <Box value="1" sx={{ padding: "0 !important" }}>
                  {reviewProduct.map((oItem, oIndex) => (
                    <div
                      className="orderProduct"
                      key={oIndex}
                      onClick={() =>
                        gotoproduct(
                          oItem.product_id,
                          oItem.item_status,
                          oItem.item_id,
                          oItem.deleted_product_status
                        )
                      }
                    >
                      <div className="orderHeader">
                        <div className="number flex justify-between items-center">
                          {oItem.seller_name}
                        </div>
                        <div className="date !flex !gap-x-2">
                          {oItem.star_count > 0 && (
                            <div className="flex">
                              {[...Array(oItem.star_count)].map((i) => (
                                <span key={i}>
                                  <Star
                                    fontSize="small"
                                    id="onestar"
                                    className="text-starColor"
                                  />
                                </span>
                              ))}
                              {[...Array(5 - oItem.star_count)].map((i) => (
                                <span key={i}>
                                  <Star
                                    fontSize="small"
                                    id="notstar"
                                    className="text-notstarColor"
                                  />
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="onePcProduct">
                        <div className="bodyProduct">
                          <div className="orderImage">
                            <img src={oItem.product_photo} />
                          </div>
                          <div className="title !flex flex-col justify-between">
                            {oItem.product_name}
                            <div className="price !ml-0">
                              {oItem.promotion_price ? (
                                <>
                                  <span className="current-price">
                                    <span className="money">
                                      {oItem.promotion_price}ks
                                    </span>
                                  </span>
                                  <span className="detailprice ">
                                    <s className="money">
                                      {oItem.simple_price}ks
                                    </s>
                                  </span>
                                </>
                              ) : (
                                <span className="current-price">
                                  <span className="money">
                                    {oItem.simple_price}ks
                                  </span>
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="qty !ml-auto">
                            <span className="">{t("Quantity")}: </span>
                            <span className="qtyNumber">{oItem.quantity}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="w-full flex justify-center pt-[15px]">
                    <Button
                      sx={{
                        bgcolor: "#014e40",
                        "&:hover": { backgroundColor: "#014e40" },
                      }}
                      variant="contained"
                      onClick={() => navigate("/Receive")}
                    >
                      {t("ReviewOtherProduct")}
                    </Button>
                  </div>
                </Box>
              ) : (
                <div className="w-full h-auto flex flex-col items-center mt-[100px]">
                  <div>{t("NoProduct")}</div>
                  <div className="w-[300px] h-[300px]">
                    <Lottie
                      animationData={nodata}
                      loop={true}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              )}
            </Box>
          </div>
          <ToastContainer draggable={false} autoClose={3000} />
        </div>
      </Container>
    </Wrapper>
  );
};

export default ReviewPage;
