import React, { useState, useEffect } from "react";
import "../../mobile/account/ReviewHistoryCss.scss";
import { useNavigate } from "react-router-dom";
import { ArrowCircleLeft, Star } from "@mui/icons-material";
import Loading from "../../components/commons/Loading";
import ApiCollections from "../../services/ApiCollections";
import { ApiRequest } from "../../services/common/ApiRequest";
import Lottie from "lottie-react";
import nodata from "../../assets/nodata.json";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReviewHistory = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goback = () => {
    navigate("/PersonalCenter");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getreviewProduct();
  }, []);

  const goRecieve = () => {
    navigate("/Recieve");
  };

  const gotoproduct = (product_id, item_status, item_id, deleteStatus) => {
    if (deleteStatus == 1) {
      toast.error("This product has been deleted.", {
        className: "toastColor",
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      if (item_status != 0) {
        navigate(`/products/product-detail/${product_id}/${item_id}`);
      } else {
        navigate(`/products/product-detail/${product_id}`);
      }
    }
  };

  const [loading, setLoading] = useState(false);

  const [reviewProduct, setreviewProduct] = useState([]);
  const getreviewProduct = async () => {
    setLoading(true);
    let requestObj = {
      method: "post",
      url: ApiCollections.reviewHistoryApi,
      params: {
        customer_id: localStorage.getItem("userTokenID"),
      },
    };

    let response = await ApiRequest(requestObj);
    setLoading(false);
    if (response.data.code === 200) {
      setreviewProduct(response.data.data);
    }
  };

  return (
    <>
      {loading && <Loading open={loading} />}
      <ToastContainer draggable={false} autoClose={3000} />
      <div className="historyDiv">
        <div className="header" id="topHeader">
          <ArrowCircleLeft id="backbtn" onClick={() => goback()} />
          <div className="title">{t("Review")}</div>
        </div>
        {reviewProduct.length > 0 ? (
          <div>
            {reviewProduct.map((item, index) => (
              <div
                className="buyProduct"
                key={index}
                onClick={() =>
                  gotoproduct(
                    item.product_id,
                    item.item_status,
                    item.item_id,
                    item.deleted_product_status
                  )
                }
              >
                <div className="top">
                  <div className="storeName">{item.seller_name}</div>
                  <div className="starsdiv">
                    {[...Array(item.star_count)].map((i) => (
                      <span key={i}>
                        <Star fontSize="small" id="onestar" />
                      </span>
                    ))}
                    {[...Array(5 - item.star_count)].map((i) => (
                      <span key={i}>
                        <Star fontSize="small" id="notstar" />
                      </span>
                    ))}
                  </div>
                </div>
                {/* <div className="pull-right fr">
                  <img v-for = 'n in parseInt(item.score)' :key = 'n' :src="imgYes">
                  <img v-for = 'n in 5-parseInt(item.score)' :key ="n + 'i'" :src="imgNo">
              </div> */}
                <div className="orderlistDiv">
                  <div className="cardImage">
                    <img src={item.product_photo} />
                  </div>
                  <div>
                    <div className="title">{item.product_name}</div>
                    <div className="price">{item.simple_price}ks</div>
                    <div className="qty">
                      {t("Quantity")}:
                      <span className="count">{item.quantity}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="noOrder">
            <div className="text">{t("NoProduct")}</div>
            <div className="iconWrap">
              <Lottie animationData={nodata} loop={true} id="lot" />
            </div>
          </div>
        )}

        <div className="goRevieve" onClick={() => goRecieve()}>
          {t("ReviewOtherProduct")}
        </div>
      </div>
    </>
  );
};

export default ReviewHistory;
